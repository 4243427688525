<template>
    <div class="pa-0 blocStat">
        <div v-if="!campaignPage">
            <div class="headingNumberStat" ref="headingStat">
                <h2>{{$t(values.title)}}</h2>
            </div>

            <v-row dense>
                <v-col v-for="(item, index) in values.data" :key="'number_card_'+index" :lg="colNumber ? colNumber : 3" md="6" sm="6"  @click="item.disabledClick ? null : $emit('openStats', item)" >
                    <NumberStatCardComponent :classProp="'hover ' + (item.disabledClick ? '' : 'forceHover')" :item="item" />
                </v-col>
            </v-row>
        </div>
        <v-card rounded="lg" class="pt-2 px-2" v-else>
            <v-card-text>
                <h2 class="my-0 pb-4" style="min-height:unset;">{{$t(values.title)}}</h2>
                <v-row dense>
                    <v-col v-for="(item, index) in values.data" :key="'number_card_'+index" :lg="colNumber ? colNumber : 3" md="6" sm="6"  @click="item.disabledClick ? null : $emit('openStats', item)" v-show="item.right ? $func.hasRight('retroplanning/displayprice') : true">
                        <NumberStatCardComponent :campaignPage="campaignPage" :tooltip="item.tooltip ? item.tooltip : false" :classProp="'hover ' + (item.disabledClick ? '' : 'forceHover')" :item="item" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

    import NumberStatCardComponent from '@/components/dashboardComponent/NumberStatCardComponent'

export default {
    name:"NumberStatComponent",
    components:{NumberStatCardComponent},
    props:['values', 'colNumber', 'campaignPage'],
    data() {
        return {
            cardHeight:0,
            titleHeight:0,
            rowNumber:0,
        }
    },
    mounted() {
        setTimeout(() => {
           this.initSize()
        }, 400);

    },
    methods: {
        initSize(){
            if(this.$refs.statCard){
                 this.cardHeight = this.$refs.statCard.$el.offsetHeight
                this.titleHeight = this.$refs.headingStat.offsetHeight
                this.rowNumber = Math.floor(this.values.data.length/4)
            }

        }
    },
    computed: {
        heightElement(){
            return "height: "+(this.cardHeight - this.titleHeight)/this.rowNumber+"px;"
        }
    },
}
</script>
<style lang="scss">

    .blocStat{
        .headingNumberStat{
            display:flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                margin-bottom: 0;
            }
        }

        .elementStat {


            text-align: center;
            h3 {
                color:inherit !important;
                font-weight: 800;
                font-size:30px;
                @media (-webkit-device-pixel-ratio: 1.25) {
                    font-size: 30px;
                    margin-top:10px;
                }
            }
            .label {
                display: block;
                font-size:13px;
            }
        }

    }
</style>
