<template>
    <v-card class="campaignHeraldStat pa-3 pt-0" v-if="campData && !campData.code">
        <v-card-text>
            <v-tabs v-model="tabId">
                <v-tab v-for="(graphData, index) in allGraphData" :key="'tab_' + index" class="primary--text">
                    {{ $t(index) }}
                </v-tab>

                <v-tabs-items v-model="tabId" class="mt-8">
                    <v-tab-item v-for="(graphData, index) in allGraphData" :key="'tab_item_' + index">
                        <div v-if="graphData.datasets && graphData.labels.length">
                            <DonutComponent :dataSet="graphData" :legend="false" style="height: 200px;"/>

                            <v-simple-table class="mt-4" dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="(legend, index) in graphData.labels">
                                            <td><span :style="'width: 20px; height:20px; display:inline-block; background-color:' + graphData.datasets[0].backgroundColor[index]"></span></td>
                                            <td><b>{{ legend }}</b></td>
                                            <td class="text-right"><b class="mr-2">{{ graphData.datasets[0].data[index] }}</b><small>{{ (100 * graphData.datasets[0].data[index] / getDataTotal(graphData.datasets[0].data)).toFixed(2) }}%</small></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                        <v-alert class="mb-0 mx-6" border="left" color="info" v-else text>
                            {{ $t("emptyDataMessage") }}
                        </v-alert>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import DonutComponent from '@/components/graphs/DonutComponent';

export default {
    name: "campaignHeralStat",
    props: ['campaign'],
    components:{ DonutComponent },
    data() {
        return {
            campData: {},
            tabId: 0,
            default_colors: ["#FAEBD7","#00FFFF","#7FFFD4", "#F5F5DC","#FFE4C4","#000000","#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#F5F5F5","#FFFF00","#9ACD32"],
            allGraphData: {  
                "MSG_DOMAINS": {},
                // "MSG_PLATFORMS": {},
                // "MSG_BROWSERS": {},
            },
        }
    },
    created() {
        if(!this.campData.total && this.$route.params.id) {
            GenericDataService.getData('/retroplanning/' + this.$route.params.id + '/getCampaignHeraldStats').then((response) => {
                this.campData = response.data.data;
                if(!this.campData.code)
                    this.generateGraphData();
            });
        }
    },
    mounted() {
        if(!this.campData.total && this.$route.params.id) {
            GenericDataService.getData('/retroplanning/' + this.$route.params.id + '/getCampaignHeraldStats').then((response) => {
                this.campData = response.data.data;
                if(!this.campData.code)
                    this.generateGraphData();
            });
        }
    },
    methods: {
        generateGraphData() {
            for (let property in this.allGraphData) {
                this.allGraphData[property] = {
                    "labels": Object.keys(this.campData[property]),
                    "datasets": [{
                        "data": Object.values(this.campData[property]),
                        "backgroundColor": this.default_colors.sort(() => 0.5 - Math.random()).slice(0, Object.keys(this.campData[property]).length)
                    }]
                }
            }
        },
        getDataTotal(datas) {
            return datas.reduce((a, b) => a + b, 0);
        }
    },
}
</script>

<style lang="scss">
    .campaignHeraldStat {
        td:first-child { width: 25px ;}
    }
</style>