<template>
    <v-card rounded="lg" class="rounded-lg pa-3 pt-0" v-if="campData.total">
        <v-card-title class="px-3 pb-4 pt-6">
            <div class="sentMsgs">
                <v-icon color="primary" class="iconSent">$campaign_d</v-icon>
                <h3 class="primary--text">{{campData.total}} messages envoyés</h3>
            </div>
        </v-card-title>
        <v-card-text class="statMsg">
            <div class="deliveredMsg" v-if="campData.MSG_DELIVERED.total.count">
                <h5 class="icon-title mb-2 mt-2" v-if="campaign.config && campaign.config.canal && (campaign.config.canal == 'MMS' || campaign.config.canal == 'VMS')" @click="getStats('SEND')"><span>Messages délivrés <v-icon small color="primary" class="ml-2 iconEye">$eye_d</v-icon></span> <span>{{campData.total}}</span></h5>
                <h5 class="icon-title mb-2 mt-2" v-else @click="getStats('MSG_DELIVERED')"><span>Messages délivrés <v-icon small color="primary" class="ml-2 iconEye">$eye_d</v-icon></span> <span>{{campData.MSG_DELIVERED.total.count}}</span></h5>
                <div v-if="campData.MSG_DELIVERED.total.count">
                    <div class="mb-6 detail" @click="getStats(index)" v-for="(detail, index) in campData.MSG_DELIVERED.details">
                        <div class="d-flex justify-space-between mb-1 label"><span>{{$t(index)}} <v-icon small color="primary" class="ml-2 mb-0 iconEye">$eye_d</v-icon></span>{{detail.count}}</div>
                        <v-progress-linear
                        rounded
                        :value="getPercent(detail.count, campData.total)"
                        ></v-progress-linear>
                    </div>
                </div>
            </div>
            <div class="notDeliveredMsg mt-8" v-if="campData.MSG_NOTDELIVERED.total.count">
                <h5 class="icon-title mb-2" @click="getStats('MSG_NOTDELIVERED')"><span>Non délivrés <v-icon small color="primary" class="ml-2 iconEye">$eye_d</v-icon></span> <span>{{campData.MSG_NOTDELIVERED.total.count}}</span></h5>
                <div v-if="campData.MSG_NOTDELIVERED.total.count">
                    <div class="mb-6 detail" @click="getStats(index)" v-for="(detail, index) in campData.MSG_NOTDELIVERED.details">
                        <div class="d-flex justify-space-between mb-1 label"><span>{{$t(index)}}<v-icon small color="primary" class="ml-2 iconEye">$eye_d</v-icon></span>{{detail.count}}</div>
                        <v-progress-linear
                        rounded
                        :value="getPercent(detail.count, campData.MSG_NOTDELIVERED.total.count)"
                        ></v-progress-linear>
                    </div>
                </div>
                <!-- <div v-if="(campData.MSG_NOTDELIVERED.total.count + campData.MSG_DELIVERED.total.count) < campData.total">
                    <div class="mb-6 detail" >
                        <div class="d-flex justify-space-between mb-1 label"><span>{{$t('noInformations')}}</span>{{(parseInt(campData.total) - (parseInt(campData.MSG_NOTDELIVERED.total.count) + parseInt(campData.MSG_DELIVERED.total.count)))}}</div>
                        <v-progress-linear
                        rounded
                        :value="getPercent((parseInt(campData.total) - (parseInt(campData.MSG_NOTDELIVERED.total.count) + parseInt(campData.MSG_DELIVERED.total.count))), campData.total)"
                        ></v-progress-linear>
                    </div>
                </div> -->
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "campaignStat",
    props:['campaign'],
    data() {
        return {
            campData: {
            }
        }
    },
    created() {
        if(!this.campData.total && this.$route.params.id){
            GenericDataService.getData('/retroplanning/'+this.$route.params.id+'/getCampaignStats').then((response) => {
                this.campData = response.data.data
            })
        }
    },
    mounted() {
        if(!this.campData.total && this.$route.params.id){
            GenericDataService.getData('/retroplanning/'+this.$route.params.id+'/getCampaignStats').then((response) => {
                this.campData = response.data.data
            })
        }
    },
    methods: {
        getStats(label){
            let item = {}
            item.label = label;
            this.$emit('openStats', item);
        },
        getPercent(value, total){
            return value*100/total
        }
    },
}
</script>
<style lang="scss">
    .statMsg {
        .icon-title {
            align-items:center;
            height:26px;
            cursor:pointer;
            .iconEye {
                display:none;
            }
            &:hover {
                .iconEye {
                    display: inline;
                }
            }
        }
        .detail {
            .label {
                color:#000;
                font-size:14px;
                cursor:pointer;
                .iconEye {
                    display:none;
                }
            }   
            &:hover {
                .iconEye {
                    display: inline;
                }
            }
        }
        
    }
    .sentMsgs {
        position:relative;
        padding:15px 10px;
        width:100%;
        font-weight:bold;
        background-color: #ecebf8;
        border-radius:10px;
        text-align:center;
        .iconSent {
            position:absolute;
            left:20px;
            top:12px;
        }
    }
</style>