<template>
    <v-card rounded="lg" class="rounded-lg pa-3 pt-0" v-if="links.data.length > 0">
         <v-card-title class="px-3 pb-4 pt-6">
            <h3 class="primary--text">{{$t('clickedlink')}}</h3>
        </v-card-title>
        <v-card-text>
            <v-simple-table dense style="border:1px solid rgba(0,0,0,0.1)">
                <template v-slot:default>
                <thead>
                    <tr class="lightgrey headersLinks">
                        <th v-for="(header, index) in links.headers" class="text-left">
                            {{$t(header)}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(link, index) in links.data"
                    :key="'link_'+index"
                    >
                        <td>{{ link.link }}</td>
                        <td>{{ link.uniqueClick }}</td>
                        <td>{{ link.clickTotalNumber }}</td>
                        <td>{{ link.clickRate }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "campaignLinkStat",
    data() {
        return {
            links: {
                headers: ["links", "uniqueClick", "clickTotalNumber", "clickRate"],
                data: []
            }
        }
    },
    created() {
        if(this.links.data.length == 0 && this.$route.params.id){
            GenericDataService.getData('/retroplanning/'+this.$route.params.id+'/getCampaignStatsLinks').then((response) => {
                this.links.data = response.data.data
            })
        }
    },
    mounted() {
        if(this.links.data.length == 0 && this.$route.params.id){
            GenericDataService.getData('/retroplanning/'+this.$route.params.id+'/getCampaignStatsLinks').then((response) => {
                this.links.data = response.data.data
            })
        }
    },
}
</script>
<style lang="scss">
    .headersLinks {
        text-transform: uppercase;
    }
</style>