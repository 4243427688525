<template>
    <div>
        <v-card rounded="lg">
            <v-card-text class="pa-0 pt-1">
                <h2 style="position:absolute;" class="ml-6">{{$t('budget')}}</h2>
                <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl" />
            </v-card-text>  
        </v-card>
    </div>
</template>
<script>
import DatatableV2 from "@/components/DatatableV2";
export default {
    name: "CampaignBudget",
    components: {
        DatatableV2,
    },
    data() {
        return {
            
        }
    },
    computed: {
        apiUrl() {
            return "/retroplanning/" + this.$route.params.id + "/getBudgetList";
        },
    },
}
</script>
<style lang="scss">
    
</style>