<template>
    <div>
        <v-row>
            <v-col :key="campaign.id" cols="12" class="colRetro">
                <v-btn v-if="(campaign && !['PROGRAMMED', 'COMPLETED'].includes(campaign.config.status)) && $func.hasRight('import/add')" small depressed class=" btnImportCamp bg-gradient mb-4" @click="displayUpload = true">
                    <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                    {{$t('ImportContact')}}
                </v-btn>
                <retroplanningContactListComponent :sousType="sousType" :loader="loading" :limitRetro="campaign" :retroplanning="campaign" @addList="addList($event, campaign)" @removeListFromLP="removeListFromLP($event, campaign)" />
            </v-col>
        </v-row>

        <addListDialogComponent :loader="loading" :displayAddList="displayAddList" :retroplanning="campaign" :excludedIds="listIdToExclude" :entities="entities" @addToList="addToList" @close="closeAddListDialog" />
        <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :returnData="true" />
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";
import Loader from '@/components/Loader'
import dateManipulation from '@/mixins/dateManipulation'
import retroplanningContactListComponent from '@/modules/operations/operationsComponents/retroplanningContactListComponent'
import importContactComponent from '@/components/importContactComponent'
import addListDialogComponent from '@/modules/operations/operationsComponents/addListDialogComponent'

export default {
    name: "contactListComponent",
    components:{FormDialogComponent, Loader, retroplanningContactListComponent, importContactComponent, addListDialogComponent},
    mixins: [getForm, dateManipulation],
    props:['loadData', 'limitRetroId', 'sousType'],
    data() {
        return {
            loading: false,
            entities : [],
            displayUpload: false,
            displayAddList: false,
            listIdToExclude:[],
        }
    },
    computed: {
        campaign(){
            return this.$store.state.campaign.campaign
        }
    },
    watch: {
        loadData: {
            immediate: true,
            handler(val) {
                if(val) {
                    this.loading = true
                    GenericDataService.getData('/entity/getSelectList2').then((response) => {
                        this.entities = response.data.data;
                        this.loading = false;
                    });
                }
            }
        }
    },
    methods: {
        closeModal(reload = false) {
            if(reload) {
                this.addToList([reload], this.campaign);
            }
            this.displayUpload = false;
        },
        addToList($event, retroplanning) {
            this.displayAddList = false;
            this.loading = true;

            let newRetroplanning = this._.cloneDeep(retroplanning);
            if(newRetroplanning.config.hasOwnProperty('contact_list')) {
                $event.forEach(id => {
                    newRetroplanning.config.contact_list.push(id);
                });
            } else {
                newRetroplanning.config.contact_list = $event;
            }

            GenericDataService.postData('/retroplanning/' + newRetroplanning.id + '/setConfigRetroPlanning', { campaign : newRetroplanning }).then((response) => {
                this.$emit('refreshContactList');
                this.listIdToExclude = [];
                this.loading = false;
            })
        },
        removeListFromLP(listId, retro) {
            this.loading = true;

            let newRetroplanning = this._.cloneDeep(retro);
            newRetroplanning.config.contact_list.splice(newRetroplanning.config.contact_list.findIndex((e) => e == listId), 1);

            GenericDataService.postData('/retroplanning/' + newRetroplanning.id + '/setConfigRetroPlanning', { campaign : newRetroplanning }).then((response) => {
                this.$emit('refreshContactList');
                this.loading = false;
            });   
        },
        addList(contactlist, retro) {
            this.listIdToExclude = retro.config.hasOwnProperty('contact_list') ? retro.config.contact_list : []
            this.displayAddList = true
        },
        closeAddListDialog(){
            this.listIdToExclude = []
            this.displayAddList = false
        },
    },
}
</script>

<style lang="scss">
.colRetro {
    position:relative;
}
.btnImportCamp{
    position:absolute;
    z-index:5;
    top:28px;
    right:34px;
}
</style>