<template>
    <v-dialog width="600" v-model="openProgramRpDialog" persistent>
        <v-card class="">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{$t('confirmation')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="mt-6">
                <h5 class="text-center">{{ $t('campaign_to_program') }} :</h5>
                <v-alert border='left' class="mb-0 mt-3" color="info" dark text>
                    <span v-html="$t('program_rp_confirmation_reminder')"></span>
                </v-alert>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <ul class="black--text">
                            <li><b>{{ $t('campaign') }} :</b> {{ retroplanning.name }}</li>
                            <li><b>{{ $t('planned_date') }} :</b> {{ parseFullDateToFr(retroplanning.config.date_start) }}</li>
                            <li><b>{{ $t('number_of_recipients') }} :</b> {{ retroplanning.config.contact_number }}</li>
                            <!-- <li><b>{{ $t('estimated_cost') }} :</b> {{ retroplanning.cost }} €</li> -->
                            <li><b>{{ $t('canal') }} :</b> {{ $t(retroplanning.config.canal) }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="6">
                       <v-btn depressed color="success" @click="program" :loading="loader">{{ $t('program') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name: "dialogProgramRpComponent",
    props: ['openProgramRpDialog', 'retroplanning'],
    mixins:[dateManipulation],
    data() {
        return {
            loader:false
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },
        program() {
            this.loader = true;
            GenericDataService.postData('/retroplanning/' + this.retroplanning.id + '/program').then((response) => {
                this.loader = false;
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
                this.$emit('refresh');
            }).catch((error) => {
                this.loader = false
            });
        }
    },
}
</script>